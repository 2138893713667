import React, { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";

const Home = lazy(() => import("pages/Home"));
const TryOn = lazy(() => import("pages/TryOn"));
const Pricing = lazy(() => import("pages/Pricing"));
const Login = lazy(() => import("pages/Login"));
const Register = lazy(() => import("pages/Register"));
const Tasks = lazy(() => import("pages/Tasks"));
const Users = lazy(() => import("pages/Users"));
const Keys = lazy(() => import("pages/Keys"));

const App = () => {
  return (
    <div className="app">
      <Suspense fallback={"Loading"}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="tryon" element={<TryOn />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/tasks/*" element={<Tasks />} />
          <Route path="/users" element={<Users />} />
          <Route path="/keys" element={<Keys />} />
        </Routes>
      </Suspense>
    </div>
  );
};

export default App;
